function triggerLinkOsso(ssoID) {
  let userAccessToken = JSON.parse(sessionStorage.getItem('currentUser'));
  let curLanguageCode = JSON.parse(localStorage.getItem('SelectedLanguage'));
  let currentCulture = curLanguageCode.cultureCode;
  let stateData = "";
  let tPRequestItem = [];
  sendOSSOForm(ssoID, userAccessToken.access_token, currentCulture, stateData, tPRequestItem);
}

function sendOSSOForm(id, acessToken, culture = 'en-us', stateData = null, tPRequestItem = []) {
  let clientSsoId = id;
  let takeOverBrowser = false;
  if (id) {
    let idArr = id.split('|');
    if (idArr.length > 1) {
      clientSsoId = idArr[0];
      takeOverBrowser = (idArr[1] == 'True');
    }
  }
  const OssoViewModel = {
    culture: culture,
    stateData: stateData,
    TPRequestItem: tPRequestItem,
    ssoID: clientSsoId,
    userToken: acessToken,
    employee_EventRecordID: ''
  };
  const url = '/SSO/OSSOInner?SSOID=' + clientSsoId;
  const stateModel = JSON.stringify(OssoViewModel);
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  if (sessionStorage.getItem('IsFromMobileApp') == 'true') {
    takeOverBrowser = true;
  }
  if (takeOverBrowser) {
    form.setAttribute('target', '_self');
  }
  else {
    form.setAttribute('target', 'OutboundExchange');
  }
  let el = document.createElement('input');
  el.setAttribute('type', 'hidden');
  el.setAttribute('name', 'ssoModelData');
  el.setAttribute('value', stateModel);
  form.append(el);
  document.querySelector('#ssoFormContainer').append(form);
  if (!takeOverBrowser) {
    window.open(
      '',
      'OutboundExchange',
      'height=600,width=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=yes, resizable=yes,location=no, status=no'
    );
  }
  form.submit();
  form.remove();
}

function jwtDecodeToken(token) {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = jwtUrlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

function jwtUrlBase64Decode(str) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw 'Illegal base64url string!';
    }
  }
  return decodeURIComponent(escape(window.atob(output)));
}

////////////////////////////////////////////////////////////// Below comes from origial aon-customize-googleanalytics.js, we merge together for performance things////////////////////////////////


function gtag() {
  dataLayer.push(arguments);
}

function setDefaultStorage() {
  let ad_storage_default = analytics_storage_default = 'denied';
  if (window.OnetrustActiveGroups.indexOf('6') != -1) {
    analytics_storage_default = 'granted';
  }

  if (window.OnetrustActiveGroups.indexOf('C0004') != -1) {
    ad_storage_default = 'granted';
  }

  gtag('consent', 'default', {
    ad_storage: ad_storage_default,
    analytics_storage: analytics_storage_default,
  });

  setDefaultStorage = function () { };
}

function listenToConsentChanges() {
  if (window.OnetrustActiveGroups) {
    setDefaultStorage();
  }

  OneTrust.OnConsentChanged && OneTrust.OnConsentChanged(function (event) {
    let ad_storage = 'denied', analytics_storage = 'denied';

    if (event.detail.indexOf('6') != -1) {
      analytics_storage = 'granted';
    }

    if (event.detail.indexOf('C0004') != -1) {
      ad_storage = 'granted';
    }

    gtag('consent', 'update', {
      ad_storage: ad_storage,
      analytics_storage: analytics_storage,
    });
  });
}
